












import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class ViessmannHeatingSystemSpotWidget extends Mixins(SpotWidgetDataMixin) {}
